export const colors = {
  primary: "#007FAE",
  secondaryBackground: "#F4EDE4",
  onSecondaryBackground: "#000000",
  tertiaryBackground: "#ffffff",
  onTertiaryBackground: "#000000",
  success: "#49ab15",
  primaryText: "#ffffff",
  secondaryText: "#d8d8d8"
};
