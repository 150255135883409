import {
  Add,
  ChevronLeft,
  ChevronRight,
  Close,
  ReplayOutlined,
  Settings,
} from "@mui/icons-material";
import { Skeleton, Typography } from "@mui/material";
import "./Browser.css";
import DocScreen from "./DocScreen";
import { RxCursorArrow } from "react-icons/rx";
import Landing from "../../landing/Landing";
import { useEffect, useState } from "react";
import LandingAnim from "./LandingAnim";
import MyWebsite from "./MyWebsite";

const Browser = () => {
  const [currentScreen, setCurrentScreen] = useState("landing");
  const [url, setUrl] = useState("docs.google.com/document/d/my-new-website");
  const [tabTwo, setTabTwo] = useState("");

  useEffect(() => {
    playAnim();
    const interval = setInterval(() => {
      playAnim();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const playAnim = () => {
    setCurrentScreen("landing");
    setUrl("docs.google.com/document/d/my-new-website");
    setTabTwo("");
    const timeout = setTimeout(() => {
      setCurrentScreen("docs");
    }, 4000);
    const timeout2 = setTimeout(() => {
      setCurrentScreen("openTabTwo");

      websiteTypingAnim();
    }, 11500);
  };

  const websiteTypingAnim = () => {
    let i = 0;
    const finalUrl = "https://www.mywebsite.com";
    const interval = setInterval(() => {
      if (i < finalUrl.length) {
        setUrl(finalUrl.substring(0, i + 1));
      } else {
        setTabTwo("My Website");
        setCurrentScreen("myWebsite");
        clearInterval(interval);
      }
      i++;
    }, [110]);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        
      }}
    >
      <div
        style={{
          width: "100%",
          height: "14%",
          backgroundColor: "#E8E8E8",
          display: "flex",
          flexDirection: "column",
          
        }}
      >
        <div style={{ width: "100%", display: "flex" }}>
          <div
            className="browser-tab one"
            style={{
              width: "15%",
              height: "100%",
              backgroundColor: "#B8B8B8",
              
              borderTopRightRadius: ".2em",
              display: "flex",
              padding: "0 .3em",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="p" fontSize={".2em"} sx={{color: 'black'}}>
              {currentScreen == "landing"
                ? "Draughn & Alink"
                : "My Website Plan"}
            </Typography>
          </div>
          {currentScreen === "myWebsite" || currentScreen === "openTabTwo" ? (
            <div
              className="browser-tab two"
              style={{
                height: "100%",
                backgroundColor: "#B8B8B8",

                borderTopRightRadius: ".2em",
                display: "flex",

                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography  sx={{color: 'black'}} variant="p" fontSize={".2em"}>
                {tabTwo}
              </Typography>
            </div>
          ) : null}
          <Add sx={{ fontSize: ".3em" }}></Add>
        </div>

        <div
          style={{
            width: "100%",
            padding: "0em",
            backgroundColor: "#D8D8D8",
            height: "60%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChevronLeft style={{ fontSize: ".4em" }} />
          <ChevronRight style={{ fontSize: ".4em" }} />
          <ReplayOutlined style={{ fontSize: ".30em" }} />
          <div
            style={{
              marginLeft: ".2em",
              width: "85%",
              height: "85%",
              borderRadius: ".5em",
              backgroundColor: "#A8A8A8",
              marginRight: "auto",
              display: "flex",
              padding: "0 .2em",
              alignItems: "center",
            }}
          >
            <Typography variant="p" fontSize=".2em"  sx={{color: 'black'}}>
              {url}
            </Typography>
          </div>
          <Settings style={{ fontSize: ".30em", marginRight: ".12em" }} />
        </div>
      </div>
      {currentScreen === "landing" ? (
        <LandingAnim />
      ) : currentScreen === "myWebsite" ? (
        <MyWebsite />
      ) : currentScreen === "openTabTwo" ? (
        <div
          style={{ width: "100%", height: "100%", backgroundColor: "white" }}
        />
      ) : (
        <DocScreen />
      )}
    </div>
  );
};

export default Browser;
