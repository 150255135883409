import { CheckCircle, Close, Pending, Send } from "@mui/icons-material";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import emailjs from "@emailjs/browser";
import { useState } from "react";

const ContactForm = () => {
  const [sentStatus, setSentStatus] = useState("none");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSentStatus("sending");

    const data = new FormData(e.currentTarget);

    await emailjs.send(
      "service_q5jnjf9",
      "template_hiqke3s",
      {
        name: `${data.get("fname")} ${data.get("lname")}`,
        email: data.get("email"),
        subject: `${data.get("cname")}: ${data.get("phone")}`,
        message: data.get("message"),
      },
      "LNy4p9X26XCFJibpY"
    );

    setSentStatus("sent");
    setTimeout(() => {
      setSentStatus(false);
    }, 3000);

    e.target.reset();
  };
  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "15px",
      }}
    >
      <FormControl
        style={{ gap: "1em", width: "100%" }}
        onSubmit={handleSubmit}
        variant="filled"
        component="form"
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <TextField
            id="fname"
            name="fname"
            label="First Name"
            variant="outlined"
            required
            color="primary"
            fullWidth
          />
          <TextField
            id="lname"
            name="lname"
            label="Last Name"
            variant="outlined"
            required
            color="primary"
            fullWidth
          />
        </Box>
        <TextField
          id="email"
          name="email"
          label="Email"
          variant="outlined"
          required
          fullWidth
        />
        <TextField
          id="phone"
          name="phone"
          label="Phone Number"
          variant="outlined"
          required
        />
        <TextField
          id="cname"
          name="cname"
          label="Company Name"
          variant="outlined"
          required
        />

        <TextField
          inputProps={{ borderColor: "white" }}
          id="message"
          name="message"
          label="How can we help you?"
          multiline
          variant="outlined"
          required
          fullWidth
          rows={5}
        />

        <button className="action-button subdued primary" type="submit">
          {sentStatus === "sending" ? (
            <Typography className="button-text">Sending...</Typography>
          ) : sentStatus === "sent" ? (
            <Typography className="button-text">Sent!</Typography>
          ) : (
            <Typography className="button-text">Submit</Typography>
          )}
        </button>
      </FormControl>
    </Box>
  );
};

export default ContactForm;
