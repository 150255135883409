export const portfolioData = [
  {
    title: "CLIQInvite",
    description:
      "CLIQInvite is a comprehensive digital event management platform incorporating innovative e-invite processes and in-depth analytics. As a web- and mobile-based platform, CLIQInvite offers several complex features including:",
    image: require("../assets/CLIQInvite_D&A.png"),
    color: "#a020f0",
    bullets: [
      "Customizable Digital E-Invites",
      "Scannable Tickets",
      "Payment Integration",
      "ID Upload & Verification",
      "Event Analytics",
      "Automated Texting",
      "Web and Mobile App",
    ],
    inverted: false,
  },
  {
    title: "EZVenues",
    description:
      "EZVenues is a web-based Air-BnB-like marketplace for private event venue rentals. Facilitating back-and-forth negotiations between businesses and customers, EZVenues includes several complex features including:",
    image: require("../assets/EZVenues_D&A.png"),
    color: "#008cff",
    bullets: [
      "Searchable Venue Listings",
      "Payment Integration",
      "Add-on Services",
      "Menu Creation and Display",
      "Built-in Negotations",
      "Dynamic Pricing and Filtering",
    ],
    inverted: true,
  },
  {
    title: "AgriFlow",
    description:
      "AgriFlow is a web and mobile-based platform that provides real-time data analytics and predictive forecasting to equip farmers with actionable insights for better decision-making. AgriFlow includes several complex features including:",
    color: "#4cceac",
    image: require("../assets/AgriFlow_D&A.png"),
    bullets: [
      "Big Data Analysis",
      "Predictive Forecasting",
      "Organization Permissions & Roles",
      "Real Time Conversions",
      "Customizable Dashboards",
      "Web and Mobile App",
    ],
    inverted: false,
  },
];
