import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../assets/colors";
import ContactForm from "./ContactForm";

const DevelopmentCycleSection = () => {
  const steps = [
    {
      title: "Review",
      description:
        "When you contact us, our Co-Founder and Co-CEOs, Steve Draughn and Mark Alink, personally receive and review your project requirements carefully.",
    },
    {
      title: "Call",
      description:
        "After the initial review, we schedule a discovery call to discuss your goals, clarify requirements, and ensure we fully understand your vision for the project.",
    },
    {
      title: "Proposal",
      description:
        "Based on our discussion, we provide a detailed proposal outlining the project scope, timeline, budget, and deliverables tailored to your needs.",
    },
    {
      title: "Development",
      description:
        "Our team begins the development phase, combining cutting-edge technologies with industry best practices to bring your project to life with precision and care.",
    },
    {
      title: "Revision",
      description:
        "Once the initial development is complete, we work closely with you to gather feedback, implement revisions, and ensure the final product exceeds your expectations.",
    },
  ];
  return (
    <Box
      className="responsive-width"
      sx={{
        padding: "10rem 0rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={12}>
        <Grid
          item
          xs={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          <Typography variant="h2" sx={{ alignSelf: "flex-start" }}>
            What's Next?
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", textAlign: "left", mb: "3rem" }}
          >
            Our five-step process ensures that we get you the product you're
            looking for as efficiently as possible
          </Typography>
          {steps.map((step) => (
            <Box key={step.title}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  mb: ".5rem",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.primary,
                    height: ".75rem",
                    width: ".75rem",
                    borderRadius: ".6rem",
                    boxShadow: `${colors.primary} 0px 0px 12px`,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ color: "white", textAlign: "left", fontSize: "1.2rem" }}
                >
                  {step.title}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: colors.secondaryText,
                  textAlign: "left",
                  ml: "calc(1.75rem)",
                }}
              >
                {step.description}
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid item xs={7}>
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DevelopmentCycleSection;
