import { Box, Button, colors, Typography } from "@mui/material";
import TypingAnimation from "../components/TypingAnimation";
import Monitor from "../components/processanim/Monitor";
import Browser from "../components/processanim/Browser";

const HeroSection = ({ handleRequestQuote }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "2rem",
        height: "calc(100dvh - 6rem)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          height: "100%",
          alignItems: "center",
        }}
        className="responsive-width"
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "80%",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "white",
                textAlign: "left",
                marginBottom: "1rem",
                fontSize: "1.2rem",
                fontWeight: 500,
              }}
            >
              SOFTWARE DEVELOPMENT COMPANY
            </Typography>
            <TypingAnimation />
            <Typography
              variant="body1"
              sx={{
                color: "white",
                textAlign: "left",
                fontSize: "1.2rem",
                fontWeight: 300,
              }}
            >
              Draughn & Alink is a software development company with a proven
              record of delivering quality products at industry-best speed.
            </Typography>
            <Box
              style={{
                display: "flex",
                marginBottom: "5rem",
                marginTop: "10%",
                alignItems: "center",
              }}
            >
              <button
                className="action-button primary"
                onClick={() => {
                  handleRequestQuote();
                }}
              >
                <Typography
                  variant="body1"
                  className="button-text"
                >
                  Get a Proposal
                </Typography>
              </button>
            </Box>
          </Box>
        </Box>
        <Monitor>
          <Browser />
        </Monitor>
      </Box>
    </Box>
  );
};

export default HeroSection;
