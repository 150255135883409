import React, { useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { colors } from "../assets/colors";
import {
  Analytics,
  ArrowForward,
  ArrowForwardIos,
  ArrowRight,
  ArrowRightAlt,
  CheckCircle,
  ChevronRight,
  Code,
  MobileFriendly,
  Monitor,
  Web,
  Palette,
  BugReport,
Security
} from "@mui/icons-material";

const ListSelectionCard = ({ title, description }) => {
  const selectionOptions = {
    "Custom Development": {
      title: "Custom Development",
      description:
        "We provide tailored solutions designed to meet your unique business needs, enhance operational efficiency, and drive growth. Our expertise ensures scalable, secure, and fully integrated applications that align seamlessly with your goals:",
      bullets: [
        "Bespoke Applications",
        "Scalable Solutions",
        "SaaS Development",
        "User-Friendly Designs",
        "Robust Security Features",
        "Comprehensive Maintenance and Support",
      ],
      icon: <Code sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
    "Mobile App Development": {
      title: "Mobile App Development",
      description:
        "We craft innovative mobile solutions for iOS and Android platforms, designed to deliver exceptional performance, intuitive user experiences, and seamless functionality for your business needs:",
      bullets: [
        "Native and Cross-Platform",
        "Custom Features",
        "User-friendly Interfaces",
        "Performance Optimization",
        "Third-Party Integration",
        "Scalable Architecture",
      ],
      icon: <MobileFriendly sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
    "Web Development": {
      title: "Web Development",
      description:
        "We create responsive, dynamic, and visually stunning websites that enhance your online presence and engage your audience while driving results:",
      bullets: [
        "Front-end and Back-end",
        "Responsive Websites",
        "Payment Integration",
        "SEO Optimization",
        "Scalable Solutions",
        "Analytics Integration",
      ],
      icon: <Web sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
    "UI/UX Design": {
      title: "UI/UX Design",
      description:
        "We specialize in crafting intuitive and engaging user experiences, combining creativity and functionality to ensure seamless interactions and impactful designs:",
      bullets: [
        "User Research & Analysis",
        "Collaborative Design Process",
        "Prototyping",
        "Responsive Visual Design",
        "Interactive Mockups",
        "Usability Testing",
      ],
      icon: <Palette sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
    "QA and Testing Services": {
      title: "QA and Testing Services",
      description:
        "Comprehensive quality assurance and testing services to ensure your software is reliable, secure, and performs flawlessly under all conditions:",
      bullets: [
        "Functional Testing",
        "Performance Testing",
        "Security Assessments",
        "Compatibility Testing",
        "Automated Testing",
        "Manual QA Processes",
      ],
      icon: <Security sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
    "Data Analytics": {
      title: "Data Analytics",
      description:
        "Transform raw data into actionable insights with advanced analytics solutions designed to support strategic decision-making and drive business growth:",
      bullets: [
        "Data Collection & Integration",
        "Real-Time Analytics",
        "Predictive Modeling",
        "Data Visualization",
        "Trend Analysis",
        "KPI Monitoring",

      ],
      icon: <Analytics sx={{ color: "white", fontSize: "2.5rem" }} />,
    },
  };
  const [selectedOption, setSelectedOption] = useState(
    Object.keys(selectionOptions)[0]
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "2rem",
        gap: "4rem",
        margin: "3rem 0rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: 1,
          overflowX: "hidden",
        }}
      >
        {Object.keys(selectionOptions).map((option, index) => {
          return (
            <Box
              className={option === selectedOption ? "selected-list-card" : ""}
              sx={{
                display: "flex",
                gap: "0.6rem",
                padding: ".75rem 1rem",
                mb: "1rem",
                width: "100%",
                boxSizing: "border-box",
                cursor: "pointer",
                transition: "all 500ms ease-in-out",
              }}
              onClick={() => setSelectedOption(option)}
              key={option}
            >
              <ArrowForward sx={{ color: "white" }} />
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2rem",
                  alignSelf: "center",
                  fontWeight: selectedOption === option ? 600 : 400,
                  textAlign: "left",
                  color: "white",
                }}
              >
                {option}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ width: "1px", backgroundColor: "#ffffff20" }}></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 2.5,
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "2rem",
              height: "4rem",
              width: "4rem",
              backgroundColor: colors.primary,
            }}
          >
            {selectionOptions[selectedOption]?.icon}
          </Box>
          <Typography
            variant="h3"
            sx={{
              fontSize: "1.7rem",
              fontWeight: 400,
            }}
          >
            {selectionOptions[selectedOption]?.title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: "1.1rem",
            fontWeight: 400,
            color: "white",
            marginBottom: "1rem",
            textAlign: "left",
          }}
        >
          {selectionOptions[selectedOption]?.description}
        </Typography>
        {selectionOptions[selectedOption]?.bullets?.map((bullet) => (
          <Box
            key={bullet}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              padding: ".4rem 0rem",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "1.1rem",
                color: "white",
              }}
            >
              •
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "1.1rem",
                color: "white",
              }}
            >
              {bullet}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ListSelectionCard;
