import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { colors } from "../assets/colors";

function TypingAnimation({ sx, style }) {
  const [currentWord, setCurrentWord] = useState("");
  const [animationRunning, setAnimationRunning] = useState(false);
  const [cursor, setCursor] = useState("");

  const words = [
    "design",
    "user experience",
    "mobile development",
    "technology",
    "web development",
    "innovation",
    "graphics",
  ];

  function cycleWords(index) {
    let word = words[index];
    let wordLength = word.length;
    let i = 0;
    let isDeleting = false;

    const typing = () => {
      const speed = isDeleting ? 50 : 150;
      setCurrentWord(word.substring(0, i));
      if (!isDeleting && i === wordLength) {
        isDeleting = true;
        setTimeout(() => {
          typing();
        }, 1500);
      } else if (isDeleting && i === 0) {
        isDeleting = false;
        index = (index + 1) % words.length;
        word = words[index];
        wordLength = word.length;
        setTimeout(() => {
          typing();
        }, 500);
      } else {
        setTimeout(() => {
          typing();
        }, speed);
      }
      i = isDeleting ? i - 1 : i + 1;
    };
    typing();
  }

  const flashCursor = () => {
    setInterval(() => {
      setCursor((old) => (old ? "" : "|"));
    }, 500);
  };

  useEffect(() => {
    if (!animationRunning) {
      setAnimationRunning((old) => {
        if (!old) {
          cycleWords(0);
          flashCursor();
        }
        return true;
      });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography
        className="slogan"
        color="white"
        variant="h1"
        textAlign="left"
        fontSize={"2.5em"}
        height="7.5rem"
      >
        We are a results-oriented firm specializing {" "}
        <span className="glow" style={{ color: colors.primary }}>
          {currentWord}
        </span>
        <span style={{ color: cursor ? "transparent" : "white" }}>{"|"}</span>
      </Typography>
    </div>
  );
}

export default TypingAnimation;
