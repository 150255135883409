import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { MenuOpen, MenuSharp } from "@mui/icons-material";
import { colors } from "../assets/colors";
import "./Header.css";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
    style: {
      ...children.style,
      backgroundColor: trigger ? `#000000` : "transparent",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Header(props) {
  const navigate = useNavigate();
  const [navAnchor, setNavAnchor] = React.useState();

  const pages = ["Home", "Portfolio"];

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar className="to-change" color="transparent">
          <Toolbar className="responsive-width" sx={{alignSelf: "center"}}>
            <Logo />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => setNavAnchor(e.currentTarget)}
              >
                <MenuSharp sx={{ color: "white", fontSize: "1.5em" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={navAnchor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(navAnchor)}
                onClose={() => setNavAnchor(null)}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => navigate(`/${page.toLowerCase()}`)}
                  >
                    <Typography
                      textAlign="center"
                      sx={{ fontFamily: "Roboto" }}
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  justifyContent: "flex-end",
                  columnGap: "2em",
                },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    display: "block",
                    fontFamily: "Roboto",
                  }}
                  onClick={() => navigate(`/${page.toLowerCase()}`)}
                >
                  <h3>{page}</h3>
                </Button>
              ))}
            </Box> */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}
