import { Menu } from "@mui/icons-material";
import { Button, Skeleton, Typography } from "@mui/material";

const MyWebsite = () => {
  return (
    <div
      className="my-website-container"
      style={{ height: "100%", width: "100%", backgroundColor: "white" }}
    >
      <div
        style={{
          height: "100%",
          margin: ".05em .3em",
        }}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
        </style>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Menu style={{ color: "white" }}></Menu>
          <Typography
            sx={{ fontFamily: "Great Vibes", mt: ".35em" }}
            fontSize={".35em"}
            variant="h1"
          >
            My Website
          </Typography>
          <Skeleton
            animation={false}
            variant="circular"
            style={{ width: ".35em", height: ".35em", backgroundColor: "white" }}
          ></Skeleton>
        </div>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: "60%",
              height: "75%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                className="skeleton"
                style={{
                  width: "60%",
                  height: "1.2em",
                  marginTop: ".3em",
                  borderRadius: ".2em",
                }}
              />
              <div
                className="skeleton"
                style={{
                  width: "40%",
                  height: ".3em",
                  marginTop: ".1em",
                  borderRadius: ".2em",
                }}
              />
            </div>
            <Typography
              variant="h3"
              style={{
                fontSize: ".35em",
                fontFamily: "Roboto",
                textAlign: "left",
              }}
            >
              "One of the most{" "}
              <b style={{ color: "olivedrab", fontWeight: 900 }}>
                critically acclaimed
              </b>{" "}
              shops in our nation!"{" "}
              <i style={{ fontFamily: "Great Vibes" }}>— New Yoik Times</i>
            </Typography>
          </div>

          <div
            style={{
              width: "30%",
              height: "80%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="skeleton"
              style={{
                width: "100%",
                height: ".3em",
                marginTop: ".3em",
                borderRadius: ".2em",
              }}
            />
            <div
              className="skeleton"
              style={{
                width: "60%",
                height: "5%",
                marginTop: ".1em",
                borderRadius: ".2em",
              }}
            />
            <div style={{ marginTop: "auto", position: 'relative' }}>
              <div className="ring one" />
              <div className="ring two" />
              <div className="ring three" />
              <button
                className="button-pulse"
                style={{
                  backgroundColor: "olivedrab",
                  border: "none",
                  outline: "none",
                  borderRadius: "8px",
                  padding: ".4em 1.2em",
                  zIndex: 3,
                  position: 'relative'
                }}
              >
                <Typography style={{ fontSize: "1.4em" }} variant="h2">
                  Order Now
                </Typography>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWebsite;
