import { Box, Grid, Modal, Typography } from "@mui/material";
import "./Landing.css";
import HeroSection from "./HeroSection";
import { colors } from "../assets/colors";
import ListSelectionCard from "./ListSelectionCard";
import { portfolioData } from "./portfolioData";
import PortfolioCard from "./PortfolioCard";
import Footer from "../components/Footer";
import DevelopmentCycleSection from "./DevelopmentCycleSection";

const Landing = () => {
  const handleRequestQuote = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Box
        id="landing-container"
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Box className="background-gradient">
          <HeroSection handleRequestQuote={handleRequestQuote} />
          <Box
            sx={{
              width: "100dvw",
              padding: "5rem 0rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              className="responsive-width"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="h2" textAlign="left">
                Why Choose Us
              </Typography>
              <Typography
                variant="body1"
                textAlign="left"
                sx={{
                  fontSize: "1.2rem",
                  marginBottom: "3rem",
                  color: "white",
                  width: "75%",
                }}
              >
                Draughn & Alink provides bespoke software development services
                with an emphasis on quality and efficiency. We deliver
                cutting-edge solutions that ensure growth, regardless of your
                business size or stage.
              </Typography>
              <ListSelectionCard />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100dvw",
            backgroundColor: colors.tertiaryBackground,
            padding: "10rem 0rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            className="responsive-width"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="h2"
              style={{ color: colors.onSecondaryBackground }}
            >
              Software Success Stories
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                width: { xl: "80%" },
                alignSelf: "center",
                marginBottom: "5rem",
              }}
            >
              Draughn & Alink provides bespoke software development services
              with an emphasis on quality and efficiency. We deliver
              cutting-edge solutions that ensure growth, regardless of your
              business size or stage.
            </Typography>
            <Grid container spacing={16}>
              {portfolioData.map((portData) => {
                return (
                  <Grid item xs={12} key={portData.title}>
                    <PortfolioCard portfolioData={portData} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box
          className="background-gradient"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DevelopmentCycleSection />
          <Footer />
        </Box>
      </Box>
    </div>
  );
};

export default Landing;
