import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors } from "../assets/colors";
import { AddCircle, DataUsage, Done, Source } from "@mui/icons-material";

const PortfolioCard = ({ portfolioData }) => {
  return (
    <Grid
      container
      direction={portfolioData.inverted ? "row-reverse" : "row"}
      sx={{}}
    >
      <Grid item xs={5} >
        <img
          src={portfolioData.image}
          style={{
            width: "100%",
            borderRadius: "1rem",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={6}>
        <Typography
          variant="h1"
          sx={{
            color: colors.onTertiaryBackground,
            textAlign: "left",
            mb: "2rem",
          }}
        >
          {portfolioData.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: colors.onTertiaryBackground,
            textAlign: "left",
            fontSize: "1.2rem",
            mb: "1rem",
            fontWeight: 300,
          }}
        >
          {portfolioData.description}
        </Typography>
        {portfolioData.bullets.map((bullet, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              mt: ".5rem",
            }}
          >
            <AddCircle
              sx={{
                color: portfolioData.color,
                fontSize: "1rem",
              }}
            />
            <Typography
              key={index}
              variant="body1"
              sx={{
                color: colors.onTertiaryBackground,
                textAlign: "left",
              }}
            >
              {bullet}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default PortfolioCard;
