import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./Monitor.css";

const Monitor = ({ children }) => {
  const windowSize = useWindowDimensions();
  return (
    <div
      className="monitor"
      style={{
        fontSize:
          windowSize.width > 1000
            ? `calc(2.5em * ${windowSize.width / 1920})`
            : `calc(2.5em * ${windowSize.width / 500})`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          height: "5em",
          width: "10em",
          alignSelf: "center",
          border: ".3em black solid",
          borderRadius: ".3em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
      <div
        style={{
          backgroundColor: "black",
          height: "1em",
          width: "1em",
          alignSelf: "center",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "black",
          height: ".4em",
          width: "5em",
          alignSelf: "center",
          borderTopRightRadius: ".3em",
          borderTopLeftRadius: ".3em",
        }}
      ></div>
    </div>
  );
};

export default Monitor;
