import { ChevronLeft, ChevronRight, TocTwoTone } from "@mui/icons-material";
import { Box, Button, List, ListItem, Typography } from "@mui/material";

import "./PortfolioModule.css";
import { GiBullets, GiFrankensteinCreature, GiMountains } from "react-icons/gi";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { Slide } from "react-slideshow-image";
import Slideshow from "./Slideshow";
const PortfolioModule = ({
  title,
  subtitle,
  images,
  link,
  isMobile,
  descriptions,
  inverted
}) => {
  return (
    <Box className={`portfolio-module-container ${inverted ? "inverted" : ""}`}>
      <div style={{ width: "40%" }}>
        <div>
          <Typography
            variant="h1"
            sx={{
              fontSize: title.length < 20 ? "5em" : "3.5em",
              textAlign: "center",
            }}
          >
            {title}
            {/* {!link && (
              <Button
                sx={{ marginLeft: "3em", fontSize: ".5em" }}
                variant="contained"
              >
                Coming Soon
              </Button>
            )} */}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "white", opacity: 0.7, textAlign: "center" }}
          >
            {subtitle}
          </Typography>

          <List>
            {descriptions?.map((desc) => (
              <ListItem>
                <Typography
                  variant="p"
                  sx={{ fontSize: "1.2em", fontStyle: "italic" }}
                >
                  • {desc}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
      {isMobile ? <div style={{ display: "flex", marginLeft: "auto", gap: "5em", position: 'relative' }}>
        {images.map((image, index) => {
          return (
            <img
              className={`${
                isMobile
                  ? `portfolio-image-${index}`
                  : `portfolio-desktop-image-${index}`
              }`}
              src={require(`../../assets/${image}`)}
       
            />
          );
        })}
      </div> : <div style={{width: '50%'}}><Slideshow/></div>}
    </Box>
  );
};

export default PortfolioModule;
