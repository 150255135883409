import { Box, Grid } from "@mui/material";
import { colors } from "../../assets/colors";
import "../portfolio/Portfolio.css";
import PortfolioModule from "./PortfolioModule";
import { useState } from "react";
import ourcity from "../../assets/ourcity-example.png";
import angiekim from "../../assets/angiekim-example.png";

const Portfolio = () => {
  const [activeBackground, setActiveBackground] = useState(ourcity);

  return (
    <Box
      className="page-container-portfolio page-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100dvh",
      }}
    >
      <Box className="responsive-width eighty projects-container">
        <Grid container sx={{ mt: "0em" }}>
          <Grid xs={12} item sx={{ position: "relative" }}>
            <h1
              className="header-portfolio"
              style={{ color: "white", fontSize: "4em", textAlign: "left" }}
            >
              Notable Projects
            </h1>
            <Box
              className="header-underline"
              sx={{
                width: "100%",
                maxWidth: "40em",
                height: "1em",
                top: "8em",
                backgroundColor: colors.primary,
                position: "absolute",
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // background: `url(${activeBackground}), radial-gradient(transparent, black)`,\

                backgroundBlendMode: "multiply",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                padding: "1em 0em",
                borderRadius: "15px",
                gap: "2em",
              }}
            >
              <PortfolioModule
                images={["OurCityPreviewOne.png", "OurCityPreviewTwo.png"]}
                title="OurCity"
                subtitle={`Full-stack mobile application offering promotions for popular bars and clubs.`}
                setActiveBackground={setActiveBackground}
                link={""}
                isMobile
                descriptions={[
                  "Custom-styled Google Maps API integration",
                  "Google Places Data API integration",
                  "Story post feature with photo customization (drawing, text)",
                  "Geofenced group chats & stories",
                  "Full AWS backend",
                  "Dynamic interest-based color scheme",
                  "Custom animations and UI components",
                  "Friend system",
                  "Paid promotion token system with PayPal API integration"
                ]}
              />
              <PortfolioModule
              inverted
                images={["WebPortalInsights.png", "ProfileScreen.png"]}
                title="CLIQInvite Web Portal"
                subtitle={"Marketing website for mobile application CLIΩ."}
                setActiveBackground={setActiveBackground}
                link={'https://cliqinvite.com/'}
                descriptions={[
                  "Custom-styled Google Maps API integration",
                  "Google Places Data API integration",
                  "Story post feature with photo customization (drawing, text)",
                  "Geofenced group chats & stories",
                  "Full AWS backend",
                  "Dynamic interest-based color scheme",
                  "Custom animations and UI components",
                  "Friend system",
                  "Paid promotion token system with PayPal API integration"
                ]}
              />
              {/* OurCity integrates the Google Places API to create a highly interactive and visual map for all users, with custom geofenced chatrooms and stories.}
              <PortfolioModule
                image={angiekim}
                title="AngieKimBooks"
                subtitle="Comprehensive author website, featuring book descriptions, news updates, and event listings in a sleek and easy-to-navigate format for bestselling author Angie Kim. Includes an editing portal for easy, no-code additions and custom animations for events including latest cover reveal."
                setActiveBackground={setActiveBackground}
                link={'https://angiekimbooks.com/'}
              />
              <PortfolioModule
                image="ourcity-example.png"
                title="CLIΩ"
                subtitle={
                  "Full-stack event management and invite distribution mobile application tailored to college fraternities. CLIQ integrates with external applications to distribute QR code invites, and uses sophisticated tracking and analytics to generate impactful insights from pre- to post-event."
                }
                setActiveBackground={setActiveBackground}
                link={'https://apps.apple.com/us/app/cli%CF%89/id1664767480'}
              />
               */}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Portfolio;
